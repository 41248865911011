import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  site: localStorage.getItem("site") ? JSON.parse(localStorage.getItem("site")) : "",
  siteAccount: [],
};

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setSite: (state, action) => {
      state.site = action.payload;
      localStorage.setItem("site", JSON.stringify(action.payload));
    },
    setSiteAccount: (state, action) => {
      state.siteAccount = action.payload;
    },
  },
});

export const { setSite, setSiteAccount } = siteSlice.actions;
export default siteSlice.reducer;
