import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  system: localStorage.getItem("system")
    ? JSON.parse(localStorage.getItem("system"))
    : 1,
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setSystem: (state, action) => {
      state.system = action.payload;
      localStorage.setItem("system", JSON.stringify(action.payload));
    },
  },
});

export const { setSystem } = systemSlice.actions;
export default systemSlice.reducer;
