import React from "react";
import { Card } from "antd";
const { Meta } = Card;
const CardMonitor = ({ title, description, icon, color, actions }) => (
  <Card actions={[<div className="ml-4 flex items-center">{actions}</div>]}>
    <Meta
      avatar={
        icon ? (
          <div
            className="w-12 h-12 flex items-center justify-center rounded-[10px] text-white text-[24px]"
            style={{ background: color }}
          >
            {icon}
          </div>
        ) : null
      }
      title={title}
      description={description}
    />
  </Card>
);
export default CardMonitor;
