import { Html, OrbitControls, useProgress } from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import React, {
  useRef,
  Suspense,
  useState,
  useEffect,
  startTransition,
} from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Hls from "hls.js";

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}

const ModelContent = () => {
  const gltf = useLoader(GLTFLoader, "model.gltf");

  const handleClick = (event) => {
    const { object } = event;
    console.log("Object position:", object.position);
  };

  return (
    <>
      <directionalLight
        position={[-1.3, 6.0, 4.4]}
        castShadow
        intensity={Math.PI * 1}
      />
      <primitive
        object={gltf?.scene}
        position={[1, 1, 0]}
        onPointerDown={handleClick}
        children-0-castShadow
      />
      <OrbitControls target={[1, 1, 0]} />
    </>
  );
};

const Model = () => {
  const [loading, setLoading] = useState(true);
  const videoRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
  const [isPlaying, setIsPlaying] = useState([false, false, false, false]);
  const [videoSizes, setVideoSizes] = useState([
    { width: 500, height: 300 },
    { width: 500, height: 300 },
    { width: 500, height: 300 },
    { width: 500, height: 300 },
  ]);

  const streamUrls = [
    "https://edge-office-01.siamai.tech/stream/ad6f6236-883a-4f83-8290-a305bebb0cc9/channel/0/hls/live/index.m3u8",
    "https://real-time-motinor.siamai.tech/1/stream/stream.m3u8", // Replace with your second stream URL
    "https://real-time-motinor.siamai.tech/2/stream/stream.m3u8", // Replace with your third stream URL
    "https://real-time-motinor.siamai.tech/3/stream/stream.m3u8", // Replace with your third stream URL
  ];

  const [videoPositions, setVideoPositions] = useState([
    { top: 0, left: 0 },
    { top: 350, left: 0 },
    { top: 700, left: 0 },
    { top: 1050, left: 0 },
  ]);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [draggingIndex, setDraggingIndex] = useState(null);

  useEffect(() => {
    streamUrls.forEach((streamUrl, index) => {
      if (Hls.isSupported()) {
        const hls = new Hls({
          // lowLatencyMode: true, // Enable Low Latency mode
        });
        hls.loadSource(streamUrl);
        hls.attachMedia(videoRefs.current[index].current);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // Do not auto play, wait for user interaction
        });

        hls.on(Hls.Events.ERROR, function (event, data) {
          console.error("HLS error:", data);
        });

        return () => {
          hls.destroy();
        };
      } else if (
        videoRefs.current[index].current.canPlayType(
          "application/vnd.apple.mpegurl"
        )
      ) {
        videoRefs.current[index].current.src = streamUrl;
        // Do not auto play, wait for user interaction
      }
    });
  }, []);

  const handlePlay = (index) => {
    const newIsPlaying = [...isPlaying];
    newIsPlaying[index] = true;
    setIsPlaying(newIsPlaying);
    videoRefs.current[index].current.play().catch((error) => {
      console.error("Error trying to play the video:", error);
    });
  };

  useEffect(() => {
    startTransition(() => {
      setLoading(false);
    });
  }, []);

  const handleMouseDown = (event, index) => {
    setDraggingIndex(index);
    setIsDragging(true);
    setDragStart({
      x: event.clientX - videoPositions[index].left,
      y: event.clientY - videoPositions[index].top,
    });
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const newLeft = event.clientX - dragStart.x;
      const newTop = event.clientY - dragStart.y;
      setVideoPositions((prevPositions) =>
        prevPositions.map((pos, index) =>
          index === draggingIndex ? { top: newTop, left: newLeft } : pos
        )
      );
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDraggingIndex(null);
  };

  const handleWheel = (event, index) => {
    event.preventDefault();
    const zoomFactor = 20; // Adjust this value as needed
    setVideoSizes((prevSizes) =>
      prevSizes.map((size, i) => {
        if (i === index) {
          const newWidth = Math.max(
            size.width + event.deltaY * -zoomFactor,
            100
          ); // Prevent size from going below 100
          const newHeight = (newWidth * 3) / 5; // Maintain aspect ratio
          return { width: newWidth, height: newHeight };
        }
        return size;
      })
    );
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <>
      <div style={{ position: "relative", height: "100vh" }}>
        {streamUrls.map((streamUrl, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              top: videoPositions[index].top,
              left: videoPositions[index].left,
              zIndex: 10,
            }}
            onMouseDown={(e) => handleMouseDown(e, index)}
            onWheel={(e) => handleWheel(e, index)} // Add this line
          >
            <video
              ref={videoRefs.current[index]}
              controls={!isPlaying[index]}
              style={{
                width: videoSizes[index].width + "px",
                height: videoSizes[index].height + "px",
                backgroundColor: "black",
              }}
            />
            {!isPlaying[index] && (
              <button
                onClick={() => handlePlay(index)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 20,
                }}
              >
                Click to Play
              </button>
            )}
          </div>
        ))}
        <Canvas
          camera={{ position: [-1.5, 2, 4] }}
          shadows
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Suspense fallback={<Loader />}>
            <ModelContent />
          </Suspense>
        </Canvas>
      </div>
    </>
  );
};

export default Model;
