import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: [],
  accountLimit: [],
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    getAccount: (state, action) => {
      state.account = action.payload;
    },
    getAccountLimit: (state, action) => {
      state.accountLimit = action.payload;
    },
  },
});

export const { getAccount, getAccountLimit } = accountSlice.actions;
export default accountSlice.reducer;
