import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkpoint: [],
};

const checkpointSlice = createSlice({
  name: "checkpoint",
  initialState,
  reducers: {
    setCheckpoint: (state, action) => {
      state.checkpoint = action.payload;
    },
  },
});

export const { setCheckpoint } = checkpointSlice.actions;
export default checkpointSlice.reducer;
