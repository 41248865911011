import React, { Component } from 'react';
import Annotation from 'react-image-annotation';

class Simple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annotations: [],
      annotation: {}
    };
  }

  onChange = (annotation) => {
    this.setState({ annotation });
  }

  onSubmit = (annotation) => {
    const { geometry, data } = annotation;

    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.random()
        }
      })
    });
  }

  render() {
    const { IMAGE_URL } = this.props; // รับค่า IMAGE_URL ผ่าน props

    return (
      <Annotation
        src={IMAGE_URL}
        alt='Two pebbles anthropomorphized holding hands'
        annotations={this.state.annotations}
        type={this.state.type}
        value={this.state.annotation}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        allowTouch
      />
    );
  }
}

export default Simple;
