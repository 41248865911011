import React from "react";

const OnlyPC = () => {
  return (
    <div>
      <h1>Only PC</h1>
    </div>
  );
};

export default OnlyPC;
