import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  noti: {}
};

const notiSlice = createSlice({
  name: "noti",
  initialState,
  reducers: {
    setNoti: (state, action) => {
      state.noti = action.payload;
    },
  },
});

export const { setNoti } = notiSlice.actions;
export default notiSlice.reducer;
