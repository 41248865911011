import { useState } from "react";
import { Button, Input } from "antd";

const DynamicInputList = ({ value, onValueChange }) => {
  const [inputs, setInputs] = useState(value); // สร้าง state สำหรับเก็บค่า input

  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
    onValueChange(newInputs);
  };

  const handleAddInput = () => {
    const newInputs = [...inputs, ""];
    setInputs(newInputs);
    onValueChange(newInputs);
  };

  const handleRemoveInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    onValueChange(newInputs);
  };

  return (
    <div>
      {inputs.map((input, index) => (
        <div key={index} className="flex items-center mt-2">
          <Input
            placeholder="คำถาม"
            key={index}
            value={input}
            onChange={(event) => handleInputChange(index, event)}
          />
          <Button
            type="primary"
            danger
            className="ml-2"
            onClick={() => handleRemoveInput(index)}
          >
            ลบ
          </Button>
        </div>
      ))}
      <Button className="mt-2" type="primary" onClick={handleAddInput}>
        + เพิ่มคำถาม
      </Button>
    </div>
  );
};

export default DynamicInputList;
