import React, { useState, useEffect } from "react";
import {
  Layout,
  Button,
  Select,
  Avatar,
  Dropdown,
  message,
  Space,
  Modal,
  Input,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
  PhoneOutlined,
  AudioOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import Drawer from "../Drawer";
import "@documate/react/dist/style.css";
import { Documate } from "@documate/react";

import { useSelector, useDispatch } from "react-redux";
import { setSystem } from "../../reducers/systemSlice";
import { setSite } from "../../reducers/siteSlice";
import Search from "../dashboard/Search";
import Upload from "../dashboard/Upload";
import ReactMarkdown from "react-markdown";

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);

const onClick = ({ key }) => {
  if (key === "1") {
    message.info("แก้ไขข้อมูลส่วนตัว");
    window.location.href = "/profile";
  } else if (key === "2") {
    message.info("ติดต่อเจ้าหน้าที่");
  } else if (key === "3") {
    message.info("ออกจากระบบ");
    localStorage.clear();
    window.location.href = "/";
  }
};

const items = [
  {
    label: "แก้ไขข้อมูลส่วนตัว",
    key: "1",
    icon: <UserOutlined />,
  },
  {
    label: "ติดต่อเจ้าหน้าที่",
    key: "2",
    icon: <PhoneOutlined />,
  },
  {
    label: "ออกจากระบบ",
    key: "3",
    icon: <LogoutOutlined />,
  },
];

const { Header } = Layout;

const HeaderComp = ({ collapsed, setCollapsed, colorBgContainer }) => {
  const dispatch = useDispatch();
  const selectedSystem = useSelector((state) => state.system.system);
  const selectedSite = useSelector((state) => state.site.site);
  const siteAccount = useSelector((state) => state.site.siteAccount);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [query, setQuery] = useState(
    "มีเหตุการณ์ผิดปกติไหม ? เกิดวันที่เท่าไหร่ สถานที่ไหน ? อะไรผิดปกติ ? รายงานมาทุกเรื่องเป็นข้อๆ ช่วยแนะนำวิธีแก้ปัญหาด้วย"
  );
  const [siteUuid, setSiteUuid] = useState(
    JSON.parse(localStorage.getItem("site"))
  );
  const [responseData, setResponseData] = useState("");
  const [response, setResponse] = useState("");

  useEffect(() => {
    setSiteUuid(JSON.parse(localStorage.getItem("site")));
  }, [localStorage.getItem("site")]);

  const handleSystemChange = (value) => {
    dispatch(setSystem(value));
  };

  const handleSiteChange = (value) => {
    dispatch(setSite(value));
  };

  const handleQuery = async () => {
    setLoading(true);
    setResponseData("");
    setResponse("");

    const response = await fetch("https://infer-rag.siamai.tech/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/event-stream",
      },
      body: JSON.stringify({
        query: query,
        site_uuid: siteUuid,
      }),
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value);
      try {
        const splitChunks = chunk.split("\n\n");
        splitChunks.forEach((data) => {
          if (data) {
            const match = data.match(/"text":\s*"([^"]*)"/);
            const text = match ? match[1] : "\n";
            setResponseData((prevData) => prevData + text);
          }
        });
      } catch (error) {
        // const splitChunks = JSON.stringify(chunk).split("\n");
        // const parsedData = splitChunks.map((chunk) => JSON.parse(chunk));
        //console.log(error);
      }
    }
  };

  return (
    <Header style={{ padding: 0, background: colorBgContainer }}>
      <Modal
        title={<p>แชทกับน้องสายไหม</p>}
        width={1200}
        open={open}
        onCancel={() => {
          setOpen(false);
          setResponseData("");
          setResponse("");
        }}
        footer={null}
      >
        <Input.Search
          placeholder="มีเหตุการณ์ผิดปกติไหม? ตอบเป็นหัวข้อ ประกอบด้วย เวลา สถานที่ รายละเอียดผิดปกติคืออะไร และคำแนะนำในการแก้ปัญหา"
          enterButton="ถามน้อง"
          size="large"
          onSearch={handleQuery}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          suffix={suffix}
        />
        {responseData ? (
          <ReactMarkdown children={responseData} />
        ) : (
          loading && <p>กำลังโหลดข้อมูล...</p>
        )}
      </Modal>
      <div className="flex justify-between">
        <div className="flex items-center">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />

          <Upload />
          <Search />

          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="หน่วยงาน"
            value={siteAccount.length > 0 ? selectedSite : "กำลังโหลดข้อมูล..."}
            onChange={handleSiteChange}
          >
            {siteAccount?.map((item) => (
              <Select.Option key={item.site_uuid} value={item.site_uuid}>
                {item.site_name}
              </Select.Option>
            ))}
          </Select>

          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="ระบบ"
            value={selectedSystem}
            onChange={handleSystemChange}
            options={[
              { value: 1, label: "VMS" },
              { value: 2, label: "Guard Patrolling" },
              { value: 3, label: "Time Attendance" },
              { value: 4, label: "Daily Report" },
              { value: 5, label: "Centralize" },
              // { value: 6, label: "IOT" },
            ]}
          />
          {/* <Documate
            endpoint="https://infer-llm.siamai.tech/ask"
            buttonLabel="ถามน้องสายไหม"
            placeholder="น้องสายไหม ยินดีให้คำปรึกษาค่ะ และช่วยธุรกิจของคุณ ถามได้เลย"
            predefinedQuestions={[
              "วิธีการลดกำลังคน บริษัทรักษาความปลอดภัย?",
              "วิธีการนำเทคโนโลยี AI มาใช้ในการรักษาความปลอดภัย?",
              "วิธีการใช้เทคโนโลยี IoT ในการรักษาความปลอดภัย?",
            ]}
          /> */}
          <Button
            className="ml-4"
            type="primary"
            style={{
              background: "linear-gradient(45deg, #ff6b6b, #f06595)",
              border: "none",
              color: "white",
            }}
            icon={<MessageOutlined />}
            onClick={() => {
              setOpen(true);
            }}
          >
            ถามน้องสายไหม
          </Button>
        </div>
        <div className="flex items-center mr-4 space-x-2">
          <Drawer />
          <Dropdown placement="bottom" menu={{ items, onClick }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Avatar
                  style={{ backgroundColor: "#001529" }}
                  icon={<UserOutlined />}
                />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComp;
