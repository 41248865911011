import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  predict: [],
};

const predictSlice = createSlice({
  name: "predict",
  initialState,
  reducers: {
    setPredict: (state, action) => {
      state.predict = action.payload;
    },
  },
});

export const { setPredict } = predictSlice.actions;
export default predictSlice.reducer;
