import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getAccountService = async (payload) => {
  const { data } = await supabase
    .from("account")
    .select()
    .eq("account_uuid", payload)
    .order("id");
  localStorage.setItem("account", JSON.stringify(data[0]));
  return await data[0];
};

export const getAccountLimitService = async (payload) => {
  const { data } = await supabase
    .from("account_limit")
    .select()
    .eq("account_uuid", payload);
  localStorage.setItem("accountLimit", JSON.stringify(data[0]));
  return await data[0];
};
